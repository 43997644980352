import { isNumber, isString } from 'lodash-es';

/**
 * Interpolates variables wrapped with `{}` in `str` with variables in `obj`
 * It will replace what it can, and leave the rest untouched
 *
 * @param {String} str
 * @param {Array|Object} obj
 *
 * @return {String}
 */
export var getInterpolatedString = function getInterpolatedString() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return (str || '').replace(/{([^{}]*)}/g, function (match, keyword) {
    var replace = obj[keyword];
    return isString(replace) || isNumber(replace) ? replace : match;
  });
};