import { getApiConfig } from "./getApiConfig";
import { mergePaths } from "./mergePaths";
export var getUrl = function getUrl() {
  var endpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'public';
  var currentConfig = getApiConfig(key);
  if (!currentConfig.endpoints[endpoint]) {
    throw new Error("API endpoint \"".concat(endpoint, "\" not found"));
  }
  return mergePaths(currentConfig.base, currentConfig.endpoints[endpoint]);
};