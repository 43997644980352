import { reduce } from 'lodash-es';

export const parseHighlighted = (data) => {
    return {
        highlighted: data.highlighted,
        categories: reduce(
            data.categories,
            (obj, value, key) => ({
                ...obj,
                [key.toLowerCase()]: value,
            }),
            {}
        ),
    };
};
