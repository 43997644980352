import { range } from "lodash";
export var getIcons = function getIcons() {
  var items = range(24);
  var url = 'https://mystats.b-cdn.net';
  return {
    light: items.map(function (index) {
      return "".concat(url, "/animal-icons/light/empty-icon-").concat(index, ".svg");
    }),
    dark: items.map(function (index) {
      return "".concat(url, "/animal-icons/dark/empty-icon-").concat(index, ".svg");
    })
  };
};