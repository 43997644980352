import { useEffect } from 'react';
import { useTheme, useTranslations } from '@wethefree/components';
import { SectionHeading } from '../../components/SectionHeading';

export const HeroText = () => {
    const { t, currentLanguage } = useTranslations();
    useEffect(() => {
        // Set the title
        document.title = t('site-title');
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', t('site-description'));
        document
            .querySelector('meta[property="og:title"]')
            .setAttribute('content', t('share-title'));
        document
            .querySelector('meta[property="og:description"]')
            .setAttribute('content', t('share-description'));
        document
            .querySelector('meta[property="og:image"]')
            .setAttribute(
                'content',
                `${window.location.origin}/images/meta-thumb.png`
            );
        document
            .querySelector('meta[property="og:url"]')
            .setAttribute('content', window.location.origin);
    }, [currentLanguage]);

    const { theme } = useTheme();
    useEffect(() => {
        document
            .querySelector('meta[name="theme-color"]')
            .setAttribute('content', theme === 'light' ? '#FFFFFF' : '#000000');
    }, [theme]);

    return (
        <div className="my-6 md:my-12 mx-auto max-w-4xl">
            <SectionHeading
                level={1}
                className="mb-2"
                align="center"
                text="hero-title"
            />
            <p className="mx-auto max-w-2xl text-sm sm:text-md md:text-xl font-semibold text-center">
                {t('hero-text')}
            </p>
        </div>
    );
};
