import { useRecoilValue } from 'recoil';
import hexToHsl from 'hex-to-hsl';
import { currentOrganization } from '../../recoilState';

export const StyleCustomizer = () => {
    const organization = useRecoilValue(currentOrganization);
    if (!organization.color) {
        return null;
    }

    const darkColor = hexToHsl(
        organization.colorDarkMode || organization.color
    );
    const lightColor = hexToHsl(organization.color);

    return (
        <style type="text/css">
            {`
            :root {
                --tw-ring-color: ${lightColor.join(' ')};
                --ring: ${lightColor.join(' ')};
                --primary: ${lightColor.join(' ')};
                --wtf-pink: ${lightColor.join(' ')};
            }
            .dark {
                --tw-ring-color: ${darkColor.join(' ')};
                --ring: ${darkColor.join(' ')};
                --primary: ${darkColor.join(' ')};
                --wtf-pink: ${darkColor.join(' ')};
            }
        `}
        </style>
    );
};
