import { capitalize } from 'lodash-es';
import * as validations from "./validations";
export var getFieldValidation = function getFieldValidation(standardField, _ref) {
  var t = _ref.t,
    z = _ref.z;
  var method = standardField.validate ? "validate".concat(capitalize(standardField.type)) : 'validateNot';
  if (!validations[method]) {
    throw new Error("No validation for type `".concat(standardField.type, "` found"));
  }
  return validations[method](standardField, {
    t: t,
    z: z
  });
};