import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { range } from 'lodash-es';
import {
    Button,
    Modal,
    useTranslations,
    ModalContainer,
    ModalTrigger,
    GeneratedStandardForm,
    StandardSelect,
    StandardTextInput,
    StandardCheckbox,
    Alert,
} from '@wethefree/components';
import './style.css';
import { SectionHeadingText } from '../../components/SectionHeading';
import { currentCodeState, currentOrganization } from '../../recoilState';

export const OneMillionForAnimals = () => {
    const { t } = useTranslations();
    const currentCode = useRecoilValue(currentCodeState);
    const [submitted, setSubmitted] = useState(false);

    const optionNumbers = range(5, 0);

    const organization = useRecoilValue(currentOrganization);
    const bgStyle = {};
    if (organization.infoImage) {
        bgStyle.backgroundImage = `url("${organization.infoImage}")`;
    }

    return (
        <>
            <ModalContainer>
                <article className="one-million-for-animals px-6 sm:px-10 md:px-16 py-8 sm:py-16 md:py-24 rounded-lg text-white">
                    <div className="-bg" style={bgStyle} />
                    <div className="relative z-20 sm:w-1/2 md:w-1/3">
                        <h2 className="text-xl sm:text-2xl md:text-3xl text-start font-semibold tracking-tight text-white uppercase mb-4">
                            <SectionHeadingText
                                text="one-million-heading"
                                boldClassName="block"
                            />
                        </h2>

                        <p className="text-white mb-6 text-sm md:text-md sm:text-xl w-3/5 sm:w-full">
                            {t('one-million-description')}
                        </p>

                        {organization.infoLink ? (
                            <Button variant="wtf-pink" size="wide" asChild>
                                <a
                                    href={organization.infoLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('one-million-sign-petition')}
                                </a>
                            </Button>
                        ) : (
                            <ModalTrigger>
                                <Button variant="wtf-pink" size="wide">
                                    {t('one-million-sign-petition')}
                                </Button>
                            </ModalTrigger>
                        )}
                    </div>
                </article>
                <Modal
                    className="p-0"
                    title={
                        <div className="text-2xl text-start font-semibold tracking-tight uppercase flex flex-row items-center p-5 pb-0">
                            <img
                                src="images/chick.png"
                                alt=""
                                className="h-16 me-3"
                            />
                            <span>
                                <SectionHeadingText text="one-million-heading" />
                            </span>
                        </div>
                    }
                >
                    <div className="p-6 pt-0 space-y-4">
                        <h4 className="text-xl font-bold">
                            {t('one-million-policy-title')}
                        </h4>

                        <div className="whitespace-pre-line text-sm line-clamp-4 md:line-clamp-none">
                            {t('one-million-policy-description')}
                        </div>
                    </div>

                    {submitted ? (
                        <div className="p-6 bg-gray-200 dark:bg-gray-900 rounded-b-md mt-4">
                            <Alert title={t('petition-success-title')}>
                                {t('petition-success-text')}
                            </Alert>
                        </div>
                    ) : (
                        <GeneratedStandardForm
                            className="p-6 bg-gray-200 dark:bg-gray-900 rounded-b-md mt-4"
                            requestObject={{
                                url: 'petitions',
                                api: 'public',
                                method: 'post',
                                parseStandardBody: (body) => ({
                                    ...body,
                                    accepts_notifications:
                                        !body.accepts_notifications,
                                    code_id: currentCode.empty
                                        ? null
                                        : currentCode.id,
                                }),
                            }}
                            footer={
                                <div className="mt-2 text-center">
                                    <a
                                        className="inline-block decoration-1 hover:text-wtf-pink"
                                        href="https://www.activism.wtf/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t('one-million-data-privacy-policy')}
                                    </a>
                                </div>
                            }
                            onAllDone={() => {
                                setSubmitted(true);
                            }}
                        >
                            <StandardSelect
                                name="veganLevel"
                                options={optionNumbers.map((count) => ({
                                    value: count.toString(),
                                    label: t(
                                        `one-million-i-will-option-${count}`
                                    ),
                                }))}
                                placeholder={t('one-million-i-will')}
                            >
                                {t('one-million-i-will')}
                            </StandardSelect>
                            <div className="flex flex-row items-end mt-4 gap-4">
                                <StandardTextInput
                                    name="name"
                                    className="grow base-0"
                                    placeholder={t('one-million-form-name')}
                                >
                                    {t('one-million-form-signed')}
                                </StandardTextInput>
                                <StandardTextInput
                                    name="email"
                                    type="email"
                                    className="grow base-0"
                                    placeholder={t('one-million-form-email')}
                                >
                                    {t('one-million-form-email')}
                                </StandardTextInput>
                            </div>
                            <StandardCheckbox
                                name="acceptsNotifications"
                                className="mt-4 flex flex-row items-center space-y-0"
                                optional
                            >
                                {t('one-million-form-no-updates')}
                            </StandardCheckbox>
                        </GeneratedStandardForm>
                    )}
                </Modal>
            </ModalContainer>
        </>
    );
};
