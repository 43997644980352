import { isUndefined, reduce } from 'lodash-es';
export var objectToArray = function objectToArray() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var keyToUse = arguments.length > 2 ? arguments[2] : undefined;
  // Create an empty array with a certain length
  var _final = Array(count).fill(undefined);

  // Return the array
  return reduce(obj, function (result, value, key) {
    var usedKey = isUndefined(keyToUse) ? key : value[keyToUse];
    result[usedKey] = value;
    return result;
  }, _final);
};