import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    Preloader,
    useTranslations,
    parseResources,
    parseLanguages,
} from '@wethefree/components';
import { useLanguageAndCodeFromUrl } from '../../hooks/useLanguageAndCodeFromUrl';
import {
    currentCodeState,
    resourcesState,
    highlightedState,
    userSelectedCodeState,
    currentOrganization,
} from '../../recoilState';
import { getLanguage } from './getLanguage';
import { appConfig } from '../../config/app';
import { parseHighlighted } from './parseHighlighted';
import { useChangeCode } from '../../hooks/useChangeCode';
import { useChangeLanguage } from '../../hooks/useChangeLanguage';
import { useAnalytics } from '../../hooks/useAnalytics';
import { CustomMainPreloader } from './preloaders/CustomMainPreloader';

const { REACT_APP_API_PLATFORM } = process.env;

export const MainPreloader = ({ children }) => {
    // Get the language and the code from the URL
    // TODO: Make sure this isn't the single source of truth
    let { language, code } = useLanguageAndCodeFromUrl();
    const changeCode = useChangeCode();
    const changeLanguage = useChangeLanguage();

    const setCurrentCode = useSetRecoilState(currentCodeState);
    const setCurrentOrganization = useSetRecoilState(currentOrganization);

    const currentCode = useRecoilValue(currentCodeState);

    const setResources = useSetRecoilState(resourcesState);
    const setHighlighted = useSetRecoilState(highlightedState);

    const { sendVisit } = useAnalytics();

    const userSelectedCode = useRecoilValue(userSelectedCodeState);

    const {
        setTranslations,
        setLanguages,
        currentLanguage,
        setCurrentLanguage,
    } = useTranslations();

    // These requests don't depend on anything - usually happen at first
    const generalRequests = [
        {
            url: 'languages',
            callback: ({ data }) => {
                // Set the languages
                const languages = parseLanguages(data.data);
                setLanguages(languages);

                // Validate and set the current language
                const newLanguage = getLanguage(language, languages);
                setCurrentLanguage(newLanguage);

                // Change the url
                if (newLanguage.code !== language) {
                    changeLanguage(newLanguage.code);
                }
            },
        },
        {
            url: 'validateCode',
            params: {
                'filter[code]': code || '-',
                include: 'team.organization',
            },
            callback: ({ data }) => {
                // TODO: What do we do if the code is not active?
                // data.data[0].is_active

                // Set the current code, since it's clearly valid
                const firstCode = data.data.find(Boolean);
                setCurrentCode(firstCode || appConfig.defaultCode);

                // If there is a first code, then send the request
                if (firstCode) {
                    sendVisit(firstCode.id, false, language);
                } else {
                    if (userSelectedCode) {
                        sendVisit(0, false, language);
                    }
                }

                // Set the current organization (for colors)
                const organization = data.data[0]?.organization || {};
                setCurrentOrganization(organization);

                // Change the url
                const newCode = firstCode?.code || '';
                if (newCode !== code) {
                    changeCode(newCode, {
                        replace: true,
                    });
                }
            },
        },
    ];

    // The thirds requests are only fetched if there is a language and a code
    const languageDependentRequests = [
        {
            url: 'resources',
            callback: ({ data }) => {
                const resources = parseResources(data.data);
                setResources(resources);
            },
            language: currentLanguage.code,
        },
        {
            url: 'translations',
            callback: ({ data }) => {
                const translations = data.data.reduce(
                    (obj, rawItem) => ({
                        ...obj,
                        [rawItem.key]: rawItem.value,
                    }),
                    {}
                );

                // Set translations
                setTranslations(translations);
            },
            language: currentLanguage.code,
            params: {
                'filter[platform]': REACT_APP_API_PLATFORM,
                ...(!currentCode?.id
                    ? {}
                    : {
                          code_id: currentCode?.id,
                      }),
            },
        },
    ];

    const codeDependentRequests = [
        {
            url: 'highlighted',
            callback: ({ data }) => {
                const parsedData = parseHighlighted(data.data);
                setHighlighted(parsedData);
            },
            language,
            params: {
                'filter[code]': code || '',
            },
        },
    ];

    return (
        <Preloader
            requests={generalRequests}
            customPreloader={() => <CustomMainPreloader />}
        >
            <Preloader
                requests={languageDependentRequests}
                refetch={[currentLanguage]}
                customPreloader={() => <CustomMainPreloader />}
            >
                <Preloader
                    requests={codeDependentRequests}
                    refetch={[code]}
                    customPreloader={() => <CustomMainPreloader />}
                >
                    {children}
                </Preloader>
            </Preloader>
        </Preloader>
    );
};
