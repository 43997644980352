import { useNavigate } from 'react-router-dom';
import { SecureStore } from '@wethefree/components';
import { useLanguageAndCodeFromUrl } from './useLanguageAndCodeFromUrl';
import { getUrl } from '../helpers/getUrl';

export const useChangeCode = () => {
    const { language } = useLanguageAndCodeFromUrl();
    const navigate = useNavigate();

    const changeCode = (code = '', ...params) => {
        SecureStore.set('code', code, 7); // 7 days expiration
        const url = getUrl(language, code);
        navigate(url, ...params);
    };

    return changeCode;
};
