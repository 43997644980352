var _excluded = ["name", "type", "sortable", "label", "className", "headerClassName", "defaultOrderBy", "children", "render", "handleClick"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
/**
 * Component used to DEFINE the columns of a datatable
 */
export var Column = function Column(_ref) {
  var name = _ref.name,
    type = _ref.type,
    sortable = _ref.sortable,
    label = _ref.label,
    className = _ref.className,
    headerClassName = _ref.headerClassName,
    defaultOrderBy = _ref.defaultOrderBy,
    children = _ref.children,
    render = _ref.render,
    handleClick = _ref.handleClick,
    props = _objectWithoutProperties(_ref, _excluded);
  return null;
};
Column.displayName = 'Column';