function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["children"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
import { isUndefined, snakeCase, startCase } from 'lodash-es';
import { getDefaultColumnProps } from "./getDefaultColumnProps";

// Definitions
import { Filters } from "../definitions/Filters";
import { Order } from "../definitions/Order";
import { Search } from "../definitions/Search";
import { TopRight } from "../definitions/TopRight";
import { RowActions } from "../definitions/RowActions";
import { Column } from "../definitions/Column";
export var parseTableChildren = function parseTableChildren() {
  var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var columns = {};
  var filters = [];
  var meta = {
    order: {
      field: '',
      order: ''
    },
    search: {
      visible: false,
      text: ''
    },
    filters: {},
    topRight: null,
    rowActions: null
  };
  var defaultColumnProps = getDefaultColumnProps();
  children.forEach(function (item) {
    var _ref = item || {},
      _ref$props = _ref.props,
      allProps = _ref$props === void 0 ? {} : _ref$props;
    var itemLabel = allProps.children,
      props = _objectWithoutProperties(allProps, _excluded);
    if (item.type.displayName === Column.displayName) {
      columns[props.name] = _objectSpread(_objectSpread({}, defaultColumnProps), {}, {
        sortable: props.type === 'custom' ? false : defaultColumnProps.sortable,
        serverName: snakeCase(props.name),
        headerClassName: !isUndefined(props.headerClassName) ? props.headerClassName : props.className,
        label: itemLabel || props.label || startCase(props.name)
      }, props);
    }
    if (item.type.displayName === Filters.displayName) {
      filters = filters.concat(item.props.children);
    }
    if (item.type.displayName === Order.displayName) {
      meta.order.field = props.field;
      meta.order.order = props.order;
    }
    if (item.type.displayName === Search.displayName) {
      meta.search.visible = true;
      meta.search.text = itemLabel;
    }
    if (item.type.displayName === TopRight.displayName) {
      meta.topRight = {
        children: itemLabel,
        className: props.className
      };
    }
    if (item.type.displayName === RowActions.displayName) {
      meta.rowActions = props.render;
    }
  });
  return {
    columns: columns,
    filters: filters,
    meta: meta
  };
};