import { atom } from 'recoil';
import { isDarkMode } from "./helpers/isDarkMode";
import { SecureStore } from "./helpers/SecureStore";
export var translationsState = atom({
  key: 'translations',
  "default": {}
});
export var currentThemeState = atom({
  key: 'theme',
  "default": SecureStore.get('theme') || (isDarkMode() ? 'dark' : 'light')
});
export var languagesState = atom({
  key: 'languages',
  "default": []
});
export var currentLanguageState = atom({
  key: 'currentLanguage',
  "default": SecureStore.get('language') || ''
});
export var confirmState = atom({
  key: 'confirm',
  "default": {}
});
export var developmentModeState = atom({
  key: 'developmentMode',
  "default": false
});