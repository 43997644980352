import { useRecoilValue } from 'recoil';
import { currentOrganization } from '../../recoilState';

export const Banner = () => {
    const organization = useRecoilValue(currentOrganization);
    if (!organization.bannerImage) {
        return null;
    }

    return (
        <div className="my-6 md:my-12">
            <img src={organization.bannerImage} alt="" />
        </div>
    );
};
