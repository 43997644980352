import { validateText } from "./validateText";
export var validateCode = function validateCode(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return validateText(field, {
    t: t,
    z: z
  }).regex(/^[a-z0-9]+$/i, {
    message: t('invalid-code-alphanum')
  }).length(5, {
    message: t('invalid-code-5chars')
  });
};