import { useTranslations } from '@wethefree/components';

export const Footer = () => {
    const { t } = useTranslations();

    return (
        <footer className="bg-gray-900 shadow">
            <nav className="px-6 sm:px-8 pt-12 sm:pt-16 mx-auto max-w-6xl text-white">
                <div className="block border-b border-gray-800 pb-4 sm:pb-6">
                    <p className="text-center text-sm sm:text-md block mb-2">
                        {t('footer-powered-by')}
                    </p>
                    <p className="text-center mt-1">
                        <a
                            className="inline-block"
                            href="http://www.activism.wtf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="/images/wtf-logo.png"
                                alt={t('footer-we-the-free')}
                            />
                        </a>
                    </p>

                    <p className="text-center mt-4 text-md sm:text-xl">
                        <a
                            className="inline-block underline decoration-1 hover:text-wtf-pink"
                            href="https://www.activism.wtf/mystats"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('footer-customize')}
                        </a>
                    </p>
                </div>
                <div className="flex py-4 sm:py-6 text-sm justify-center">
                    <a
                        className="text-wtf-pink hover:text-white"
                        href="https://www.activism.wtf/privacy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('footer-privacy')}
                    </a>
                    <div className="w-px bg-gray-700 mx-6" />
                    <a
                        className="text-wtf-pink hover:text-white"
                        href="https://www.activism.wtf/message"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('footer-contact')}
                    </a>
                </div>
            </nav>
        </footer>
    );
};
