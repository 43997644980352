import { useRecoilValue } from 'recoil';
import { objectToArray, useTranslations } from '@wethefree/components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ResourceList } from '../../components/ResourceList';
import { highlightedState, resourcesState } from 'src/recoilState';

export const ResourcesTab = ({ category, itemLayout = 'vertical' }) => {
    const { t, currentLanguage } = useTranslations();

    // Get highlighted resources and all resources
    const highlighted = useRecoilValue(highlightedState);
    const resources = useRecoilValue(resourcesState);

    const items = objectToArray(highlighted.categories[category] || {})
        .filter((id) => resources.byId[id])
        .map((id) => resources.byId[id]);

    return (
        <>
            {items && items.length > 0 && (
                <div className="flex flex-row items-top">
                    <div className="me-2">
                        {currentLanguage.direction === 'ltr' ? (
                            <ChevronRight className="text-wtf-pink w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:text-4xl" />
                        ) : (
                            <ChevronLeft />
                        )}
                    </div>
                    <div className="text-white text-sm sm:text-md">
                        {t('resources-text')}
                    </div>
                </div>
            )}

            <ResourceList
                layout={itemLayout === 'horizontal' ? 'list' : 'grid'}
                itemLayout={itemLayout}
                items={items}
            />
        </>
    );
};
