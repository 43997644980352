import { useState, useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isArray } from 'lodash-es';
import {
    Alert,
    ModalContainer,
    ModalTrigger,
    Modal,
    Button,
    CodeInput,
    Spinner,
    useTranslations,
    fetchRequest,
    SecureStore,
} from '@wethefree/components';
import {
    currentCodeState,
    currentOrganization,
    userSelectedCodeState,
} from '../../recoilState';
import { validateCode } from './validateCode';
import { appConfig } from '../../config/app';
import { useChangeCode } from '../../hooks/useChangeCode';
import { useLanguageAndCodeFromUrl } from '../../hooks/useLanguageAndCodeFromUrl';
import { useAnalytics } from '../../hooks/useAnalytics';

const { REACT_APP_API_PLATFORM } = process.env;

export const CodeSelector = ({ className = '' }) => {
    const currentCode = useRecoilValue(currentCodeState);
    const userSelectedCode = useRecoilValue(userSelectedCodeState);

    const fromUrl = useLanguageAndCodeFromUrl();
    const userHasSelectedCodeBefore = fromUrl.code || userSelectedCode;

    const setCurrentCode = useSetRecoilState(currentCodeState);
    const setUserSelectedCode = useSetRecoilState(userSelectedCodeState);
    const setCurrentOrganization = useSetRecoilState(currentOrganization);

    const changeCode = useChangeCode();
    const { t, setTranslations, currentLanguage } = useTranslations();

    const [error, setError] = useState(false);
    const [modalVisible, setModalVisible] = useState(
        !userHasSelectedCodeBefore
    );
    const [loading, setLoading] = useState(false);
    const [typedCode, setTypedCode] = useState('');

    const codeInputRef = useRef(null);

    const { sendVisit } = useAnalytics();

    // We need to select the input text when it becomes
    // visible or when the form errrors
    useEffect(() => {
        if (modalVisible && codeInputRef.current) {
            codeInputRef.current.select();
        }
    }, [modalVisible, error]);

    // Method to reset the form back to normal
    // used when submitting and closing the modal
    const resetForm = () => {
        // Reset the form
        setTypedCode('');

        // Set to show no error
        setError(false);
    };

    const sendDataToApi = async (code) => {
        try {
            // No error and is loading
            setError(false);
            setLoading(true);

            // Validate the code
            const data = await fetchRequest({
                url: 'validateCode',
                params: {
                    'filter[code]': code || '-',
                    include: 'team.organization',
                },
            });

            if (!isArray(data.data) || data.data.length <= 0) {
                throw new Error(t('entercode-error'));
            }

            // The code is correct
            const firstCode = data.data.find(Boolean);
            // Set the recoil store
            setCurrentCode(firstCode);

            // Send the visit
            sendVisit(firstCode.id, true);

            // Mark that the user selected the code in recoil store and local
            // storage
            setUserSelectedCode(true);
            SecureStore.set('userSelectedCode', true, 28); // 28 days expiration

            // Set the current organization (for colors)
            const organization = data.data[0]?.organization || {};
            setCurrentOrganization(organization);

            // Set the new translations
            const translationsServerData = await fetchRequest({
                url: 'translations',
                language: currentLanguage.code,
                params: {
                    'filter[platform]': REACT_APP_API_PLATFORM,
                    ...(!firstCode?.id
                        ? {}
                        : {
                              code_id: firstCode?.id,
                          }),
                },
            });
            const translations = translationsServerData.data.reduce(
                (obj, rawItem) => ({
                    ...obj,
                    [rawItem.key]: rawItem.value,
                }),
                {}
            );
            setTranslations(translations);

            // Change the url
            changeCode(code);

            // Hide the modal and reset the form
            setModalVisible(false);
            resetForm();
        } catch (error) {
            console.error(`Error fetching code:`, error);

            // Set the error
            setError(error.message);

            // Focus on the code
            codeInputRef.current.select();
        } finally {
            setLoading(false);
        }
    };

    // Method that gets called when the form
    // gets submitted g
    const onSubmit = (newCode) => {
        // Validate the code first
        const validationResponse = validateCode(newCode);
        if (validationResponse !== true) {
            setError(t(validationResponse));
            return;
        }

        // Send to the API
        sendDataToApi(newCode);
    };

    return (
        <ModalContainer
            open={modalVisible}
            onOpenChange={(value) => {
                if (userHasSelectedCodeBefore) {
                    setModalVisible(value);
                }
            }}
        >
            <ModalTrigger>
                <button
                    type="button"
                    className={`hidden bg-gray-700 hover:bg-gray-500 sm:flex flex-row items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-300 hover:text-gray-100 transition-colors group ${className}`}
                    onClick={() => setModalVisible(true)}
                    title={
                        currentCode.empty
                            ? t('entercode-header-tooltip-add')
                            : t('entercode-header-tooltip-change')
                    }
                >
                    <div
                        className={`text-xs text-gray-500 ${!currentCode.empty ? 'me-2' : 'italic'} group-hover:text-gray-300 transition-colors`}
                    >
                        {currentCode.empty
                            ? t('entercode-header-no-code')
                            : t('entercode-header-current-code')}
                    </div>
                    {!currentCode.empty && (
                        <div className="uppercase">{currentCode.code}</div>
                    )}
                </button>
            </ModalTrigger>

            <Modal
                onClose={() => {
                    setModalVisible(false);
                    resetForm();
                }}
                noPadding
                showCloseButton={Boolean(userHasSelectedCodeBefore)}
                showTitle
                title={
                    currentCode.empty
                        ? t('entercode-heading')
                        : t('entercode-change-"{0}"', [currentCode.code])
                }
            >
                <div className="text-center mt-6 px-6  text-gray-600 dark:text-gray-300">
                    {t('entercode-text')}
                </div>
                <form
                    className="p-2 sm:p-6 relative"
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit(typedCode);
                    }}
                >
                    {loading && (
                        <div className="absolute z-20 top-0 left-0 right-0 bottom-0 flex flex-row items-center justify-center bg-white/85 dark:bg-gray-800/85">
                            <Spinner />
                        </div>
                    )}
                    <div className="text-center">
                        <div className="inline-block items-center">
                            {error && (
                                <Alert
                                    variant="destructive"
                                    className="mb-4 w-full"
                                >
                                    {error}
                                </Alert>
                            )}

                            <CodeInput
                                name="code"
                                ref={codeInputRef}
                                errored={Boolean(error)}
                                disabled={loading}
                                onComplete={(newCode) => {
                                    onSubmit(newCode);
                                }}
                                onChange={(value) => {
                                    setTypedCode(value);
                                }}
                                value={typedCode}
                            />
                            <Button type="submit" className="mt-4 w-full">
                                {t('entercode-submit')}
                            </Button>

                            <div className="flex items-center mt-2 justify-end">
                                <Button
                                    variant="link"
                                    size="auto"
                                    onClick={(e) => {
                                        e.preventDefault();

                                        // Set the recoil store
                                        setCurrentCode(appConfig.defaultCode);

                                        // Send the visit
                                        sendVisit(0, false);

                                        // Mark that the user selected the code in recoil store and local
                                        // storage
                                        setUserSelectedCode(true);
                                        SecureStore.set(
                                            'userSelectedCode',
                                            true,
                                            28
                                        ); // 28 days expiration

                                        // Change the url
                                        changeCode(appConfig.defaultCode.code);

                                        // Hide the modal and reset the form
                                        setModalVisible(false);
                                        resetForm();
                                    }}
                                >
                                    {t('entercode-no-code')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <img src="/images/bg-code.jpg" alt="" draggable="false" />
            </Modal>
        </ModalContainer>
    );
};
