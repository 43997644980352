import { validateText } from "./validateText";
export var validateDatepicker = function validateDatepicker(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return validateText(field, {
    t: t,
    z: z
  });
  // .date({
  //     required_error: t('It is required'),
  //     invalid_type_error: t('That\'s not a date!'),
  // });
};