import { Skeleton } from '@wethefree/components';

export const CustomMainPreloader = () => (
    <>
        <Skeleton className="sticky top-0 w-full h-16" />

        <div className="px-6 px-8 mx-auto max-w-6xl">
            {/* Hero Text */}
            <div className="my-6 md:my-12 mx-auto max-w-4xl flex flex-col items-center">
                <Skeleton className="w-full w-10/12 h-[48px] mb-2" />
                <Skeleton className="w-10/12 w-8/12 h-[20px] mb-1" />
                <Skeleton className="w-10/12 w-8/12 h-[20px]" />
            </div>

            {/* Hero Movie */}
            <Skeleton className="aspect-video w-full h-auto" />
            <div className="py-6">
                <Skeleton className="w-full h-[32px] mb-1.5" />
                <Skeleton className="w-10/12 h-[20px] mb-1" />
                <Skeleton className="w-8/12 h-[20px]" />
            </div>

            <div className="grid grid-cols-2 gap-6 md:gap-10 lg:gap-14 items-stretch mt-6 mt-6 md:mt-10 lg:mt-14 ">
                <div>
                    <Skeleton className="aspect-video w-full h-auto" />
                    <div className="py-6">
                        <Skeleton className="w-full h-[32px] mb-1.5" />
                        <Skeleton className="w-10/12 h-[20px] mb-1" />
                        <Skeleton className="w-8/12 h-[20px]" />
                    </div>
                </div>
                <div>
                    <Skeleton className="aspect-video w-full h-auto" />
                    <div className="py-6">
                        <Skeleton className="w-full h-[32px] mb-1.5" />
                        <Skeleton className="w-10/12 h-[20px] mb-1" />
                        <Skeleton className="w-8/12 h-[20px]" />
                    </div>
                </div>
            </div>
        </div>
    </>
);
