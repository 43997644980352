function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { useRecoilState } from 'recoil';
import { get, isString } from 'lodash-es';
import moment from 'moment';

// Import moment locales
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/tr';
// import 'moment/locale/no';
import 'moment/locale/pt';
import 'moment/locale/nl';
import 'moment/locale/id';
import 'moment/locale/ru';
import 'moment/locale/bg';
import 'moment/locale/hi';
// import 'moment/locale/zh';
import 'moment/locale/ka';
import 'moment/locale/ar';
import 'moment/locale/th';
import 'moment/locale/he';
import { translationsState, languagesState, currentLanguageState } from "../recoilState";
import { getInterpolatedString } from "../helpers/getInterpolatedString";
import { SecureStore } from "../helpers/SecureStore";
export var useTranslations = function useTranslations() {
  // Handle translations
  var _useRecoilState = useRecoilState(translationsState),
    _useRecoilState2 = _slicedToArray(_useRecoilState, 2),
    translations = _useRecoilState2[0],
    setTranslations = _useRecoilState2[1];
  var t = function t(key) {
    var translation = get(translations, key, key);
    if (!isString(translation)) {
      translation = key;
    }
    for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      params[_key - 1] = arguments[_key];
    }
    return getInterpolatedString.apply(void 0, [translation].concat(params));
  };

  // Handle languages
  var _useRecoilState3 = useRecoilState(languagesState),
    _useRecoilState4 = _slicedToArray(_useRecoilState3, 2),
    languages = _useRecoilState4[0],
    setLanguages = _useRecoilState4[1];
  var _useRecoilState5 = useRecoilState(currentLanguageState),
    _useRecoilState6 = _slicedToArray(_useRecoilState5, 2),
    currentLanguage = _useRecoilState6[0],
    _setCurrentLanguage = _useRecoilState6[1];
  return {
    // Pass the translations helpers
    t: t,
    setTranslations: setTranslations,
    // Pass the languages helpers
    languages: languages,
    setLanguages: setLanguages,
    currentLanguage: currentLanguage,
    setCurrentLanguage: function setCurrentLanguage(item) {
      // Set the language for moment
      moment.locale(item.code);

      // Set the current language
      SecureStore.set('language', item.code);

      // Save to recoil
      _setCurrentLanguage(item);
    }
  };
};