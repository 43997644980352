function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["value", "children"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
import { traverseElements } from "../../../helpers/traverseElements";
import { StandardCheckbox } from "../definitions/StandardCheckbox";
import { getGeneratedComponentMatrix } from "./getGeneratedComponentMatrix";
export var getSchema = function getSchema(children) {
  var matrix = getGeneratedComponentMatrix();
  var searchableElements = Object.keys(matrix);
  var values = {};
  var schema = {};
  traverseElements(children, searchableElements, function (child) {
    var _child$props = child.props,
      value = _child$props.value,
      children = _child$props.children,
      props = _objectWithoutProperties(_child$props, _excluded);

    // If, for some reason, this component doesn't exist
    if (!matrix[child.type.displayName]) {
      return child;
    }

    // Get the type and the component
    var type = matrix[child.type.displayName].type;

    // Get the name of the field
    var name = props.name;

    // Generate the field schema
    var fieldSchema = _objectSpread({
      type: type,
      name: name,
      label: children || props.label,
      value: value
    }, props);

    // If it's a checkbox
    if (child.type.displayName === StandardCheckbox.displayName) {
      fieldSchema.checkbox = true;
    }

    // Save the value and the schema
    values[name] = value;
    schema[name] = fieldSchema;
  });
  return {
    values: values,
    schema: schema
  };
};