import { validateText } from "./validateText";
export var validatePassword = function validatePassword(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return validateText(field, {
    t: t,
    z: z
  }).min(8, {
    message: t('minimum-password')
  });
};