import { getStandardSchema } from "../helpers/getStandardSchema";
import { getStandardRequestObject } from "../helpers/getStandardRequestObject";
import { getStandardDefaultValues } from "../helpers/getStandardDefaultValues";
import { useFormSubmit } from "./useFormSubmit";
import { useStandardSchema } from "./useStandardSchema";
export var useStandardForm = function useStandardForm(_ref) {
  var _ref$schema = _ref.schema,
    schema = _ref$schema === void 0 ? {} : _ref$schema,
    requestObject = _ref.requestObject,
    onSuccess = _ref.onSuccess,
    onError = _ref.onError,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? {} : _ref$options;
  var standardSchema = getStandardSchema(schema, options);
  var _useFormSubmit = useFormSubmit({
      requestObject: getStandardRequestObject(requestObject, standardSchema),
      onSuccess: onSuccess,
      onError: onError,
      options: options
    }),
    error = _useFormSubmit.error,
    loading = _useFormSubmit.loading,
    formSubmit = _useFormSubmit.formSubmit;

  // Get the form properties
  var form = useStandardSchema(standardSchema);
  return {
    error: error,
    loading: loading,
    form: {
      instance: form,
      submit: formSubmit,
      trigger: form.trigger,
      reset: function reset(values) {
        var defaultValues = getStandardDefaultValues(standardSchema);
        form.reset(values || defaultValues);
      }
    }
  };
};