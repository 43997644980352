function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Definitions
import { StandardCheckbox } from "../definitions/StandardCheckbox";
import { StandardCodeInput } from "../definitions/StandardCodeInput";
import { StandardCombobox } from "../definitions/StandardCombobox";
import { StandardDatePicker } from "../definitions/StandardDatePicker";
import { StandardTextInput } from "../definitions/StandardTextInput";
import { StandardNumberInput } from "../definitions/StandardNumberInput";
import { StandardPasswordInput } from "../definitions/StandardPasswordInput";
import { StandardSelect } from "../definitions/StandardSelect";
import { StandardSwitch } from "../definitions/StandardSwitch";
import { StandardTextarea } from "../definitions/StandardTextarea";
import { StandardToggleGroup } from "../definitions/StandardToggleGroup";
import { StandardUploadInput } from "../definitions/StandardUploadInput";

// Replacements
import { FormFieldCheckbox } from "../../Checkbox";
import { FormFieldCodeInput } from "../../CodeInput";
import { FormFieldCombobox } from "../../Combobox";
import { FormFieldDatePicker } from "../../DatePicker";
import { FormFieldTextInput } from "../../TextInput";
import { FormFieldNumberInput } from "../../NumberInput";
import { FormFieldPasswordInput } from "../../PasswordInput";
import { FormFieldSelect } from "../../Select";
import { FormFieldSwitch } from "../../Switch";
import { FormFieldTextarea } from "../../Textarea";
import { FormFieldToggleGroupInput } from "../../ToggleGroupInput";
import { FormFieldUploadInput } from "../../UploadInput";
export var getGeneratedComponentMatrix = function getGeneratedComponentMatrix() {
  var _ref;
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, StandardCheckbox.displayName, {
    Component: FormFieldCheckbox,
    type: 'boolean'
  }), StandardCodeInput.displayName, {
    Component: FormFieldCodeInput,
    type: 'code'
  }), StandardCombobox.displayName, {
    Component: FormFieldCombobox,
    type: 'combobox'
  }), StandardDatePicker.displayName, {
    Component: FormFieldDatePicker,
    type: 'datepicker'
  }), StandardTextInput.displayName, {
    Component: FormFieldTextInput,
    type: 'text'
  }), StandardNumberInput.displayName, {
    Component: FormFieldNumberInput,
    type: 'number'
  }), StandardPasswordInput.displayName, {
    Component: FormFieldPasswordInput,
    type: 'password'
  }), StandardSelect.displayName, {
    Component: FormFieldSelect,
    type: 'select'
  }), StandardSwitch.displayName, {
    Component: FormFieldSwitch,
    type: 'boolean'
  }), StandardTextarea.displayName, {
    Component: FormFieldTextarea,
    type: 'textarea'
  }), _defineProperty(_defineProperty(_ref, StandardToggleGroup.displayName, {
    Component: FormFieldToggleGroupInput,
    type: 'togglegroup'
  }), StandardUploadInput.displayName, {
    Component: FormFieldUploadInput,
    type: 'upload'
  });
};