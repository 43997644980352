import { Globe } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
    ThemeToggle,
    useTranslations,
    LanguagePicker,
} from '@wethefree/components';
import { CodeSelector } from '../../components/CodeSelector';
import { useChangeLanguage } from '../../hooks/useChangeLanguage';
import { currentCodeState, currentOrganization } from '../../recoilState';

export const Header = () => {
    const { t } = useTranslations();
    const changeLanguage = useChangeLanguage();
    const organization = useRecoilValue(currentOrganization);

    const mobileLogoUrl =
        organization.headerLogoMobile ||
        organization.headerLogo ||
        '/images/3movies-logo.svg';
    const logoUrl = organization.headerLogo || '/images/3movies-logo.svg';
    const logoTitle = organization.name || t('site-title');

    const currentCode = useRecoilValue(currentCodeState);
    const code = (currentCode.code || '').toLowerCase();

    return (
        <header className="sticky top-0 bg-gray-800 shadow z-50">
            <nav className="px-6 sm:px-8 mx-auto max-w-6xl flex flex-row items-center justify-between h-16">
                <div className="flex flex-row items-center">
                    <Link className="flex-shrink-0" to={`/${code}`}>
                        <img
                            className="h-8 hidden md:inline-block"
                            src={logoUrl}
                            alt={logoTitle}
                        />
                        <img
                            className="h-8 inline-block md:hidden"
                            src={mobileLogoUrl}
                            alt={logoTitle}
                        />
                    </Link>
                    {!organization.headerLogo && (
                        <CodeSelector className="ms-4" />
                    )}
                </div>
                <div className="flex flex-row items-center">
                    <ThemeToggle className="text-white" size="icon" />
                    <LanguagePicker
                        className="text-white"
                        icon={<Globe className="w-4 h-4 me-1 lg:me-2" />}
                        variant="ghost"
                        align="bottom-bottom-right"
                        onChange={(item) => {
                            changeLanguage(item.code);
                        }}
                    />
                </div>
            </nav>
        </header>
    );
};
