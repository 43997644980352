import { useEffect } from 'react';
import { Toaster, useTheme, useTranslations } from '@wethefree/components';
// Components
import { MainPreloader } from './components/MainPreloader';
import { CurrentResource } from './components/CurrentResource';
import { StyleCustomizer } from './components/StyleCustomizer';
// Sections
import { Movies } from './sections/Movies';
import { Header } from './sections/Header';
import { Banner } from './sections/Banner';
import { HeroText } from './sections/HeroText';
import { Challenges } from './sections/Challenges';
import { Resources } from './sections/Resources';
import { OneMillionForAnimals } from './sections/OneMillionForAnimals';
import { Footer } from './sections/Footer';
// API Config
import { setupPublicApi } from './helpers/setupPublicApi';
// Styling
import './base.css';

const App = () => {
    const { theme } = useTheme();
    setupPublicApi();
    const { currentLanguage } = useTranslations();

    useEffect(() => {
        document.body.classList.remove('light', 'dark');
        document.body.classList.add(theme);
        document.body.dir = currentLanguage.direction;
        document.body.direction = currentLanguage.direction;
    }, [theme, currentLanguage.direction]);

    return (
        <div
            className={`app
        bg-white dark:bg-black
        text-black dark:text-white
        ${theme}`}
            data-testid="app"
            dir={currentLanguage.direction}
        >
            <MainPreloader>
                <StyleCustomizer />
                <Header />

                <main>
                    <div className="px-6 sm:px-8 mx-auto max-w-6xl">
                        <Banner />
                        <HeroText />
                        <Movies />
                        <Challenges />
                    </div>

                    <div className="py-6 sm:py-10 md:py-24 bg-gray-100 dark:bg-gray-800 mt-8 md:mt-16 lg:mt-24">
                        <div className="px-6 sm:px-8 mx-auto max-w-6xl">
                            <Resources />
                        </div>
                    </div>

                    <div className="px-6 sm:px-8 py-6 sm:py-10 md:py-24 mx-auto max-w-6xl">
                        <OneMillionForAnimals />
                    </div>
                </main>

                <Footer />

                <CurrentResource />
                <Toaster />
            </MainPreloader>
        </div>
    );
};

export default App;
