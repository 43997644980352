import { isString } from 'lodash-es';
import { useMatches } from 'react-router-dom';
import { SecureStore } from '@wethefree/components';
import { validateCode } from '../components/CodeSelector/validateCode';

export const useLanguageAndCodeFromUrl = () => {
    const [urlMatches] = useMatches();
    const params = urlMatches.params || {};

    let language = SecureStore.get('language') || '';
    let code = SecureStore.get('code') || '';

    // If nothing is passed
    if (!isString(params.language)) {
        return {
            language,
            code,
        };
    }

    if (
        params.language.length === 5 &&
        validateCode(params.language) === true
    ) {
        code = params.language;
    } else {
        language = params.language;
    }

    if (isString(params.code)) {
        language = params.language;

        if (validateCode(params.code) === true) {
            code = params.code;
        }
    }

    return {
        language,
        code,
    };
};
