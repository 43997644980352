import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { getZodSchema } from "./getZodSchema";
import { useTranslations } from "../useTranslations";
import { parseSuperRefinements } from "./parseSuperRefinements";
import { getValuesFromSchema } from "./getValuesFromSchema";
export var useStandardSchema = function useStandardSchema() {
  var standardSchema = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _useTranslations = useTranslations(),
    t = _useTranslations.t;

  // Get the zod schema
  var _getZodSchema = getZodSchema(standardSchema, {
      t: t,
      z: z
    }),
    zodSchema = _getZodSchema.zodSchema,
    defaultValues = _getZodSchema.defaultValues;

  // Get the values
  var values = getValuesFromSchema(standardSchema);

  // Make the zod object
  var zodObject = z.object(zodSchema).superRefine(function (values, ctx) {
    parseSuperRefinements(standardSchema, {
      values: values,
      ctx: ctx,
      t: t
    });
  });
  return useForm({
    resolver: zodResolver(zodObject),
    defaultValues: defaultValues,
    values: values
  });
};