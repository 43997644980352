import { each, isArray, isPlainObject } from 'lodash-es';
import { getUrl } from "./api/getUrl";
import { getParamName } from "./getParamName";
/**
 * Form the request object to be sent to the fetch method
 *
 * @param {Object} requestConfig
 *
 * @return {Request}
 */
export var getRequestObject = function getRequestObject(requestConfig) {
  // Form the body (if any)
  var body;
  if (requestConfig.body) {
    if (requestConfig.upload) {
      body = new FormData();
      each(requestConfig.body, function (value, key) {
        body.append(key, value);
      });
    } else {
      body = JSON.stringify(requestConfig.body);
    }
  }

  // Form the method
  var method = requestConfig.method || 'GET';

  // Form the url
  var url = getUrl(requestConfig.url, requestConfig.api || 'public');
  if (isArray(requestConfig.segments)) {
    // If there is any suffix to the url
    url += '/' + requestConfig.segments.join('/');
  }
  if (isPlainObject(requestConfig.params)) {
    var urlParams = new URLSearchParams();
    var _loop = function _loop(param) {
      var paramValue = requestConfig.params[param];
      if (isArray(paramValue)) {
        paramValue.forEach(function (paramItem) {
          urlParams.append(getParamName(param) + '[]', paramItem);
        });
      } else {
        urlParams.set(getParamName(param), requestConfig.params[param]);
      }
    };
    for (var param in requestConfig.params) {
      _loop(param);
    }
    url += '?' + urlParams.toString();
  }
  return {
    url: url,
    method: method,
    body: body
  };
};