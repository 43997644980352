import { _config } from "./config";
export var setupApi = function setupApi(_ref) {
  var _ref$base = _ref.base,
    base = _ref$base === void 0 ? '' : _ref$base,
    _ref$endpoints = _ref.endpoints,
    endpoints = _ref$endpoints === void 0 ? {} : _ref$endpoints,
    _ref$name = _ref.name,
    name = _ref$name === void 0 ? 'public' : _ref$name;
  _config[name] = {
    base: base,
    endpoints: endpoints
  };
};