var createGlobalStore = function createGlobalStore() {
  var store = new Map();
  return {
    set: function set(key, value) {
      store.set(key, value);
    },
    get: function get(key) {
      return store.get(key);
    },
    has: function has(key) {
      return store.has(key);
    },
    "delete": function _delete(key) {
      store["delete"](key);
    },
    clear: function clear() {
      store.clear();
    }
  };
};
export var globals = createGlobalStore();