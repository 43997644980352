import { validateText } from "./validateText";
export var validateEmail = function validateEmail(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return validateText(field, {
    t: t,
    z: z
  }).email({
    message: t('signup-invalid-email')
  });
};