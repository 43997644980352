import { PlayIcon, AppleIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

import {
    Button,
    Image,
    Modal,
    ModalContainer,
    useTranslations,
} from '@wethefree/components';

import './style.css';
import { isFunction } from 'lodash-es';
import { isDiscordResource } from '../../helpers/isDiscordResource';
import { DiscordModal } from '../DiscordModal';

export const ResourceViewer = ({ item, open, onOpenChange }) => {
    const { t } = useTranslations();

    if (isDiscordResource(item)) {
        return (
            <DiscordModal open={open} onOpenChange={onOpenChange} item={item} />
        );
    }

    const link = item.links[0];
    const plyrProps = {
        source: {
            type: 'video',
            sources: [
                {
                    src: link.url,
                    provider: link.type,
                },
            ],
        },
        options: {
            autoplay: true,
        },
    };

    const isApp = link.type === 'playstore' || link.type === 'appstore';

    return (
        <div className="resource-viewer">
            <ModalContainer open={open} onOpenChange={onOpenChange}>
                <Modal
                    aspectRatio="video"
                    className="w-full sm:w-2/3 overflow-hidden"
                    setWidth={false}
                    title={isApp ? null : item.title}
                    description={isApp ? null : item.description}
                >
                    {link.type === 'embedded' && (
                        <div
                            className="embedded"
                            dangerouslySetInnerHTML={{ __html: link.url }}
                        />
                    )}
                    {(link.type === 'youtube' || link.type === 'vimeo') && (
                        <Plyr {...plyrProps} />
                    )}
                    {isApp && (
                        <div className="grid grid-cols-3 gap-4 items-stretch">
                            <div className="bg-gray-200 dark:bg-gray-900 rounded-lg">
                                <Image src={item.image} />
                            </div>
                            <div className="col-span-2 flex flex-col justify-between">
                                <div className="space-y-3">
                                    <div className="text-2xl font-semibold leading-none tracking-tight">
                                        {item.title}
                                    </div>
                                    <div className="text-muted-foreground">
                                        {item.description}
                                    </div>
                                </div>

                                <div className="flex flex-row items-center space-x-3 mt-3">
                                    {item.links.map((link) => (
                                        <Button
                                            key={`link-${link.type}`}
                                            variant="simple"
                                            className="w-full"
                                            asChild
                                            onClick={() => {
                                                if (isFunction(onOpenChange)) {
                                                    onOpenChange(false);
                                                }
                                            }}
                                        >
                                            <Link
                                                to={link.url}
                                                target="_blank"
                                                rel="nofollow"
                                            >
                                                {link.type === 'playstore' && (
                                                    <PlayIcon className="me-2 h-6 w-6" />
                                                )}
                                                {link.type === 'appstore' && (
                                                    <AppleIcon className="me-2 h-6 w-6" />
                                                )}
                                                {t('app-' + link.type)}
                                            </Link>
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </Modal>
            </ModalContainer>
        </div>
    );
};
