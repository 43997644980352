import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    SecureStore,
    useRequest,
    useTranslations,
} from '@wethefree/components';
import { analyticsState } from '../recoilState';

const { REACT_APP_API_PLATFORM } = process.env;

export const useAnalytics = () => {
    const analytics = useRecoilValue(analyticsState);
    const setAnalytics = useSetRecoilState(analyticsState);
    const { request } = useRequest();
    const { currentLanguage } = useTranslations();

    return {
        sendVisit: async (codeId, usedCodeDialog = false, language) => {
            if (
                SecureStore.get('visit') === (codeId || -1) &&
                SecureStore.get('visitId') > 0
            ) {
                return;
            }

            const body = {
                platform: REACT_APP_API_PLATFORM,
                code_id: codeId || null,
                used_code_dialog: usedCodeDialog,
            };

            const requestObject = {
                url: 'analyticsVisit',
                api: 'public',
                method: 'post',
                language: language || currentLanguage.code,
                body,
            };

            SecureStore.set('visit', codeId || -1, 1);

            await request(requestObject, false, (data) => {
                SecureStore.set('visitId', data.data.id, 1);

                setAnalytics({
                    visitId: data.data.id,
                });
            });
        },
        sendResource: async (resourceId) => {
            const requestObject = {
                url: 'analyticsResource',
                api: 'public',
                method: 'post',
                language: currentLanguage.code,
                body: {
                    resource_id: resourceId,
                    parent_id: analytics.visitId,
                },
            };

            await request(requestObject);
        },
    };
};
