import { useSetRecoilState } from 'recoil';
import { Resource as CommonResource } from '@wethefree/components';
import { currentResourceState } from '../../recoilState';
import { isResourceEmbeddable } from './isResourceEmbeddable';
import { getResourceUrl } from './getResourceUrl';
import { useAnalytics } from '../../hooks/useAnalytics';
import { isDiscordResource } from '../../helpers/isDiscordResource';

export const Resource = ({
    item,
    layout = 'vertical',
    imageAspect,
    highlighted = false,
}) => {
    const setCurrentResource = useSetRecoilState(currentResourceState);

    const { sendResource } = useAnalytics();

    return (
        <CommonResource
            className="flex-grow"
            image={item.image}
            imageFit="contain"
            imageAspect={imageAspect}
            title={item.title}
            description={item.description}
            layout={layout}
            highlighted={highlighted}
            descriptionClassName="line-clamp-4"
            onClick={() => {
                sendResource(item.id);

                if (!isResourceEmbeddable(item) && !isDiscordResource(item)) {
                    const url = getResourceUrl(item);
                    window.open(url);
                }

                // We set the current item regardless
                setCurrentResource(item);
            }}
        />
    );
};
