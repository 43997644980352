import { SecureStore } from '@wethefree/components';
import { atom } from 'recoil';

export const resourcesState = atom({
    key: 'resources',
    default: {},
});

export const highlightedState = atom({
    key: 'highlighted',
    default: {},
});

export const analyticsState = atom({
    key: 'analytics',
    default: {
        visitId: SecureStore.get('visitId' || 0),
    },
});

// -----------------------------------------------------------------------------
// Current states (these change often)
// -----------------------------------------------------------------------------

export const userSelectedCodeState = atom({
    key: 'userSelectedCode',
    default: SecureStore.get('userSelectedCode') || false,
});

export const currentCodeState = atom({
    key: 'currentCode',
    default: null,
});

export const currentResourceState = atom({
    key: 'currentResource',
    default: '',
});

export const currentOrganization = atom({
    key: 'currentOrganization',
    default: {},
});
