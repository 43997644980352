import { _config } from "./config";
export var getApiConfig = function getApiConfig() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'public';
  if (!_config[name]) {
    throw new Error("API \"".concat(name, "\" not setup. Please run `setupApi` to set it up"));
  }
  if (!_config[name].base) {
    throw new Error("Base For API \"".concat(name, "\" not setup."));
  }
  if (!_config[name].endpoints) {
    throw new Error("Endpoints For API \"".concat(name, "\" not setup."));
  }
  return _config[name];
};